import React from 'react';
import { Image } from 'react-datocms';
import Iframe from 'react-iframe';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';

import Layout from '../components/Layout/Layout';
import { getHomePage } from '../lib/dato';

export default function HomePage({ home }) {
  const settings = {
    dots: false,
    autoplaySpeed: 1500,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout title="Home">
      <div className="Home_Container">
        <img src="/images/band.png" className="Tire_Image" alt="Tire" />
        <Image data={home.headerimage?.responsiveImage} />
        <section className="Home Content_Block Full_Width">
          <div className="Content_Block_Text">
            <h2>{home.title}</h2>
            <ReactMarkdown>{home.description}</ReactMarkdown>
          </div>
          <div className="Content_Block_Image">
            <Image data={home.descriptionimage?.responsiveImage} />
          </div>
        </section>
        <div className="Home_Assets">
          {home.promoVideo?.providerUid ? (
            <Iframe
              title="Promo Video"
              url={`https://www.youtube.com/embed/${home.promoVideo?.providerUid}?rel=0&hd=1`}
              width="100%"
              className="Youtube_IFrame"
              display="inline"
              position="relative"
              allowFullScreen
            />
          ) : null}
          {home.slideshowimages && home.slideshowimages.length > 0 && (
            <div className="SliderContent">
              <Slider {...settings}>
                {home.slideshowimages.map((item, index) => (
                  <div key={index}>
                    <Image data={item.responsiveImage} />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export async function getStaticProps() {
  const home = await getHomePage();
  return {
    props: { home },
  };
}
